@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  color: white;
}

body {
  background-color: #002fa7;
}

header, main, footer {
  padding: 2rem;
}

header {
  padding-bottom: 0rem;
}

main {
  padding-top: 0rem;
}

footer {
  padding-top: 0rem;
}

.section {
  padding-top: 2rem;
  padding-bottom: 0rem;
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  text-align: left;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 400;
}

h2 {
  color: #879eda;
  margin: 0;
}

h1 {
  margin: 0;
}

p, h1, h2, h3, h4, h5, h6 {
  line-height: 1.6;
}
p, h1, h2, h3, h4, h5, h6, span {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  max-width: 300px;
}

.blue-link {
  background-color: hsla(0,0%,100%,.08);
}

.no-underline {
  text-decoration-line: none;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.blue-link:hover {
  background-color: hsla(0, 0%, 100%, .12);
}

a {
  line-height: 160%;
  background-color: rgba(0, 0, 0, .08);
  padding: 2px 4px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color .2sease;
}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

*, :after, :before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 0rem;
}